<template>
  <div class="layout-embed">
    <div class="content">
      <slot />
    </div>
    <div id="modal-portal-target" />
  </div>
</template>

<script setup lang="ts">
// Disable user zooming on the page
useHead({
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no",
    },
  ],
});
</script>

<style lang="scss" scoped>
.layout-embed {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .content {
    height: 100%;
  }
}
</style>
